<template>
  <dialog-wrapper ref="dialog"
                  :title="$t('Cargo')">
    <template v-slot:activator="{open}">
      <v-card outlined
              :class="{'error lighten-5': hasValidationErrors}"
              @click="open">
        <v-card-text class="text--primary">
          <v-row no-gutters>
            <v-col>
              <v-sheet outlined
                       class="pa-0">
                <v-data-table :headers="headers"
                              :items="rows"
                              hide-default-footer
                              :items-per-page="100"
                              :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
                              disable-filtering
                              disable-pagination
                              disable-sort
                              dense
                              class="ma-0">
                  <template v-slot:item.tons="scope">
                    {{scope.value|finance(3)}}
                  </template>
                  <template v-slot:item.cbm="scope">
                    {{scope.value|finance(2)}}
                  </template>
                </v-data-table>
              </v-sheet>
              <v-row v-if="data.cargo"
                     no-gutters>
                <v-col v-if="data.cargo.adr"
                       cols="12">
                  <span class="text--secondary">ADR:</span> {{data.cargo.adr}}
                </v-col>
                <v-col v-if="data.cargo.thermalRequirements"
                       cols="12">
                  <v-row no-gutters
                         align="center">
                    <v-col class="flex-grow-0 pr-1 text-no-wrap text--secondary">
                      {{$t('Thermal requirements')}}:
                    </v-col>
                    <v-col v-if="isDefined(data.cargo.thermalRequirementsMin) || isDefined(data.cargo.thermalRequirementsMax)">
                      <span v-if="isDefined(data.cargo.thermalRequirementsMin)">
                        от {{data.cargo.thermalRequirementsMin|temperature}}
                      </span>
                      <span v-if="isDefined(data.cargo.thermalRequirementsMin) && isDefined(data.cargo.thermalRequirementsMax)">
                        &#8210;
                      </span>
                      <span v-if="isDefined(data.cargo.thermalRequirementsMax)">
                        до {{data.cargo.thermalRequirementsMax|temperature}}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-sheet class="body-2 pa-1 mt-2"
                       outlined
                       rounded>
                <span class="text-pre-wrap">{{data.comment}}</span>
              </v-sheet>
            </v-col>
            <v-col v-if="data.id"
                   cols="12">
              <pcc-order-warehouse-accounting :order="data" />
            </v-col>
            <v-col v-if="changed"
                   class="flex-grow-0 pl-1">
              <v-icon color="warning"
                      small>
                mdi-alert
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <pcc-order-cargo-properties-edit v-model="data"
                                     :validation-errors="validationErrors" />
    <template v-slot:actions="{close}">
      <v-btn @click="close">
        {{$t('Close')}}
      </v-btn>
    </template>
  </dialog-wrapper>
</template>
<script>
import PccOrderCargoPropertiesEdit from '@/views/Orders/PropertiesParts/PccOrderCargoPropertiesEdit';
import PccOrderWarehouseAccounting from '@/views/Orders/PropertiesParts/WarehouseAccounting/PccOrderWarehouseAccounting.vue';

export default {
  name: 'PccOrderCargoProperties',
  components: {PccOrderWarehouseAccounting, PccOrderCargoPropertiesEdit},
  props: {
    value: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    eventBus: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('Name'),
          align: 'start',
          value: 'name',
          class: 'primary white--text',
        },
        {
          text: this.$t('Tons'),
          align: 'end',
          sortable: false,
          value: 'tons',
          class: 'primary white--text',
        },
        {
          text: this.$t('cbm'),
          align: 'end',
          sortable: false,
          value: 'cbm',
          class: 'primary white--text',
        },
        {
          text: this.$t('Price'),
          align: 'end',
          sortable: false,
          value: 'price',
          class: 'primary white--text',
        },
        {
          text: this.$t('Country'),
          align: 'start',
          sortable: false,
          value: 'country',
          class: 'primary white--text',
        },
        {
          text: 'Ldm',
          align: 'end',
          sortable: false,
          value: 'ldm',
          class: 'primary white--text',
        },
        {
          text: this.$t('ItemsShort'),
          align: 'end',
          sortable: false,
          value: 'qty',
          class: 'primary white--text',
        },
      ],
      changed: false,
    };
  },
  computed: {
    rows () {
      return [
        {
          name: this.data.cargoShortDescription,
          tons: this.data.cargoWeight,
          cbm: this.data.cargoVolume,
          price: (this.data.declaredValueInNationalCurrency || '') + ' ' + (this.data.declaredValueCurrency || ''),
          country: this.data.cargoOriginCountry ? this.data.cargoOriginCountry.code : '- - -',
          ldm: this.data.cargoLDM,
          qty: this.data.cargoPlaces,
        },
      ];
    },
    data: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        this.changed = true;
      },
    },
    hasValidationErrors () {
      return this.validationErrors.hasErrorsRecursive('cargoShortDescription')
        || this.validationErrors.hasErrorsRecursive('cargoWeight')
        || this.validationErrors.hasErrorsRecursive('cargoVolume')
        || this.validationErrors.hasErrorsRecursive('cargoPlaces')
        || this.validationErrors.hasErrorsRecursive('declaredValueInNationalCurrency')
        || this.validationErrors.hasErrorsRecursive('declaredValueCurrency')
        || this.validationErrors.hasErrorsRecursive('cargoOriginCountry')
        || this.validationErrors.hasErrorsRecursive('cargo.adr')
        || this.validationErrors.hasErrorsRecursive('cargo.thermalRequirements')
        || this.validationErrors.hasErrorsRecursive('cargo.thermalRequirementsMin')
        || this.validationErrors.hasErrorsRecursive('cargo.thermalRequirementsMax')
        || this.validationErrors.hasErrorsRecursive('comment');
    },
  },
  created () {
    this.eventBus.$on('saved', this.onSaved);
  },
  destroyed () {
    this.eventBus.$off('saved', this.onSaved);
  },
  methods: {
    isDefined (value) {
      return value !== undefined && value !== '';
    },
    onSaved () {
      this.changed = false;
    },
  },
};
</script>

<style scoped>

</style>
