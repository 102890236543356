<template>
  <dialog-wrapper ref="dialog"
                  :title="$t(title)"
                  @open="onDialogOpen">
    <template v-slot:activator="{open}">
      <v-card outlined
              class="flex-column d-flex"
              :class="{'fill-height': $vuetify.breakpoint.mdAndUp, 'error lighten-5': hasValidationErrors}"
              :disabled="!service || !service.id"
              @click="onOpen(open)">
        <v-card-title class="d-flex pb-1">
          <v-row no-gutters
                 align="center">
            <v-col class="body-1 text--secondary">
              {{$t(title)}}
            </v-col>
            <v-col class="flex-grow-0 body-1 text-no-wrap">
              {{data.date|dateShort}}
            </v-col>
            <v-col v-if="changed"
                   class="flex-grow-0 pl-1">
              <v-icon color="warning"
                      small>
                mdi-alert
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="text--primary d-flex flex-grow-1">
          <v-row dense>
            <v-col cols="12">
              <div class="properties-grid">
                <div class="properties-grid-label text--secondary">
                  {{$t(type === 'dispatch' ? 'Shipper' : 'Consignee')}}:
                </div>
                <div>
                  {{data|accessor('shipperConsignee', '- - -')}}
                </div>
                <div class="properties-grid-span">
                  <v-divider />
                </div>
                <div class="properties-grid-label text--secondary">
                  {{$t('Address')}}:
                </div>
                <div>
                  {{data|accessor('address.postCode', '- - -')}}, {{data|accessor('address.country.name','- - -')}},
                  {{data|accessor('address.city','- - -')}},
                  <br>
                  {{data|accessor('address.street')}},
                </div>
                <div class="properties-grid-span">
                  <v-divider />
                </div>
                <div class="properties-grid-label text--secondary">
                  {{$t('Contacts')}}:
                </div>
                <div>
                  {{data|accessor('contacts','- - -')}}
                </div>
                <div class="properties-grid-label text--secondary">
                  {{$t('Loading methods')}}:
                </div>
                <div>
                  <template v-for="(method, loadingMethodIndex) in data.loadingMethods">
                    {{method|getIntlString('intlNames', method.defaultName)}}{{loadingMethodIndex + 1 >= data.loadingMethods.length ? '' : ','}}
                  </template>
                  <template v-if="data.loadingMethods.length < 1">
                    {{$t('anyMultiple')}}
                  </template>
                </div>
                <div class="properties-grid-label text--secondary text-no-wrap">
                  {{$t('Loading number')}}:
                </div>
                <div>
                  {{data|accessor('loadingNumber', '- - -')}}
                </div>
                <template v-if="$getValue(data, 'dispatchTerms.courierDeliveryToWarehouse')">
                  <div class="properties-grid-label text--secondary text-no-wrap">
                    {{$t('courierDelivery')}}:
                  </div>
                  <div>
                    {{data|accessor('courierDeliveryService.name', '---')}}
                  </div>
                  <div class="properties-grid-label text--secondary">
                    {{$t('trackingNumber')}}:
                  </div>
                  <div>
                    {{data|accessor('courierDeliveryTrackingNumber', '---')}}
                  </div>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-sheet class="ma-0 pa-2 lighten-3 d-flex"
                 :color="'grey'">
          <template v-if="!!shipmentTerms">
            {{shipmentTerms.description}}
          </template>
          <template v-else>
            {{$t(type + 'Terms')}}: {{$t('notSpecifiedMultiple')}}
          </template>
        </v-sheet>
        <v-overlay :value="!!data.toDelete"
                   absolute
                   z-index="4"
                   opacity=".90"
                   @click.capture.prevent.stop="t=0">
          <v-row dense
                 justify="center">
            <v-col class="flex-grow-0">
              <v-icon x-large
                      color="error">
                mdi-delete-forever
              </v-icon>
            </v-col>
            <v-col cols="12" />
            <v-col class="flex-grow-0">
              <v-btn color="warning"
                     text
                     @click="data.toDelete = false">
                {{$t('cancel')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-overlay>
      </v-card>
    </template>
    <pcc-order-cargo-handling-properties-edit v-model="data"
                                              :type="type"
                                              :service="service"
                                              :validation-errors="validationErrors"
                                              :warehouse-address-collection="warehouseAddressCollection" />
    <template v-slot:actions="{close}">
      <v-row no-gutters>
        <v-col class="flex-grow-0">
          <v-btn @click="close">
            {{$t('Close')}}
          </v-btn>
        </v-col>
        <v-col class="px-1" />
        <v-col class="flex-grow-0">
          <v-btn color="error"
                 @click="onDelete(close)">
            {{$t('delete')}}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </dialog-wrapper>
</template>

<script>
import PccOrderCargoHandlingPropertiesEdit from '@/views/Orders/PropertiesParts/PccOrderCargoHandlingPropertiesEdit';

export default {
  name: 'PccOrderCargoHandlingProperties',
  components: {PccOrderCargoHandlingPropertiesEdit},
  props: {
    value: {
      type: Object,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    eventBus: {
      type: Object,
      required: true,
    },
    createOrderMode: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      changed: false,
      warehouseAddressCollection: [],
    };
  },
  computed: {
    title () {
      if (this.type === 'dispatch') {
        return 'Loading';
      } else {
        return 'Delivery';
      }
    },
    shipmentTerms () {
      if (this.type === 'dispatch') {
        return this.data.dispatchTerms;
      } else {
        return this.data.deliveryTerms;
      }
    },
    data: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        this.changed = true;
      },
    },
    hasValidationErrors () {
      return this.validationErrors.hasErrorsRecursive('shipperConsignee')
        || this.validationErrors.hasErrorsRecursive('date')
        || this.validationErrors.hasErrorsRecursive('address')
        || this.validationErrors.hasErrorsRecursive('loadingMethods')
        || this.validationErrors.hasErrorsRecursive('dispatchTerms')
        || this.validationErrors.hasErrorsRecursive('deliveryTerms')
        || this.validationErrors.hasErrorsRecursive('loadingNumber')
        || this.validationErrors.hasErrorsRecursive('courierDeliveryService')
        || this.validationErrors.hasErrorsRecursive('courierDeliveryTrackingNumber');
    },
  },
  created () {
    this.eventBus.$on('saved', this.onSaved);
    if (this.value.prototype && !this.createOrderMode) {
      this.changed = true;
      this.$nextTick(() => {
        this.$refs.dialog.onOpen();
      });
    }
  },
  destroyed () {
    this.eventBus.$off('saved', this.onSaved);
  },
  methods: {
    onSaved () {
      this.changed = false;
    },
    onDelete (close) {
      this.data.toDelete = true;
      this.$emit('delete');
      close();
    },
    async loadWarehouseAddressCollection () {
      const success                   = await this.API.get(['warehouse-address-collection']);
      this.warehouseAddressCollection = success.data;
    },
    onOpen (open) {
      if (!this.data.toDelete) {
        open();
      }
    },
    onDialogOpen () {
      this.loadWarehouseAddressCollection();
    },
  },
};
</script>

<style scoped>

</style>
