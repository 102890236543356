import { render, staticRenderFns } from "./PccOrderCargoHandlingProperties.vue?vue&type=template&id=7065cdca&scoped=true&"
import script from "./PccOrderCargoHandlingProperties.vue?vue&type=script&lang=js&"
export * from "./PccOrderCargoHandlingProperties.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7065cdca",
  null
  
)

export default component.exports