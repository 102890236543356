<template>
  <v-row dense
         align="start">
    <v-col cols="12">
      <v-text-field v-model="model.cargoShortDescription"
                    :label="$t('Description')"
                    :error-messages="validationErrors.collect('cargoShortDescription')"
                    counter="90"
                    @input="onChanged" />
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="model.cargoWeight"
                    :label="$t('Weight')"
                    :error-messages="validationErrors.collect('cargoWeight')"
                    @input="onChanged" />
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="model.cargoVolume"
                    :label="$t('volumeCbm')"
                    :error-messages="validationErrors.collect('cargoVolume')"
                    @input="onChanged" />
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="model.cargoPlaces"
                    :label="$t('Cargo places')"
                    :error-messages="validationErrors.collect('cargoPlaces')"
                    @input="onChanged" />
    </v-col>
    <v-col cols="6">
      <v-text-field v-model="model.declaredValueInNationalCurrency"
                    :label="$t('Declared value')"
                    :error-messages="validationErrors.collect('declaredValueInNationalCurrency')"
                    @input="onChanged" />
    </v-col>
    <v-col cols="6">
      <trol-lazy-select v-model="model.declaredValueCurrency"
                        :label="$t('Currency')"
                        :error-messages="validationErrors.collect('declaredValueCurrency')"
                        item-text="name"
                        item-value="name"
                        item-disabled="dummy"
                        items-url="/currencies/lazy-select"
                        selected-url="/currencies/lazy-select"
                        @input="onChanged" />
    </v-col>
    <v-col cols="6">
      <trol-lazy-select v-model="model.cargoOriginCountry"
                        :label="$t('Origin country')"
                        :error-messages="validationErrors.collect('cargoOriginCountry')"
                        item-text="name"
                        item-value="id"
                        item-disabled="dummy"
                        items-url="/countries/lazy-select"
                        clearable
                        return-object
                        hide-details
                        @input="onChanged" />
    </v-col>
    <v-col cols="6">
      <v-text-field v-model="model.cargo.adr"
                    :label="$t('ADR')"
                    :error-messages="validationErrors.collect('adr')"
                    counter="300"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col class="pb-8 flex-grow-0 text-no-wrap">
      <v-checkbox v-model="model.cargo.thermalRequirements"
                  :label="$t('Thermal requirements')"
                  :error-messages="validationErrors.collect('cargo.thermalRequirements')"
                  hide-details
                  @change="onChanged" />
    </v-col>
    <v-col>
      <v-fade-transition>
        <v-row v-if="model.cargo.thermalRequirements"
               no-gutters>
          <v-col class="px-1">
            <v-text-field v-model="model.cargo.thermalRequirementsMin"
                          :label="$t('Min')"
                          :error-messages="validationErrors.collect('cargo.thermalRequirementsMin')"
                          clearable
                          @input="onChanged" />
          </v-col>
          <v-col class="px-1">
            <v-text-field v-model="model.cargo.thermalRequirementsMax"
                          :label="$t('Max')"
                          :error-messages="validationErrors.collect('cargo.thermalRequirementsMax')"
                          clearable
                          @input="onChanged" />
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
    <v-col cols="12">
      <v-textarea v-model="model.comment"
                  :label="$t('Comment')"
                  :error-messages="validationErrors.collect('comment')"
                  rows="3"
                  @input="onChanged" />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'PccOrderCargoPropertiesEdit',
  props: {
    validationErrors: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array],
      default () {
        return {};
      },
    },
  },
  computed: {
    model: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onChanged () {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style scoped>

</style>
