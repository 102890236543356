<template>
  <v-row dense>
    <v-col cols="6">
      <v-menu ref="shipperConsigneeMenu"
              v-model="shipperConsigneeMenuOpen"
              transition="slide-y-transition"
              bottom
              left
              offset-y
              :disabled="warehouseAddressCollection.length < 1"
              :close-on-content-click="false">
        <template v-slot:activator="{on}">
          <v-text-field v-model="model.shipperConsignee"
                        :label="$t(shipperConsignee)"
                        :error-messages="validationErrors.collect('shipperConsignee')"
                        counter="255"
                        clearable
                        @input="onChanged"
                        v-on="on" />
        </template>
        <v-list dense>
          <v-list-item link>
            <v-list-item-content v-for="(item, index) in warehouseAddressCollection"
                                 :key="index"
                                 @click="onWarehouseAddressClick(item)">
              {{item.shipperConsignee}}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="6">
      <trol-date-picker v-model="model.date"
                        :label="$t('Expected date')"
                        :error-messages="validationErrors.collect('date')"
                        clearable
                        @input="onChanged" />
    </v-col>
    <v-col cols="12">
      <v-text-field v-model="model.address.postCode"
                    :label="$t('Postal code')"
                    :error-messages="validationErrors.collect('address.postCode')"
                    counter="64"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col cols="6">
      <trol-lazy-select v-model="model.address.country"
                        :label="$t('Country')"
                        :error-messages="validationErrors.collect('address.country')"
                        item-text="code"
                        item-value="id"
                        item-disabled="dummy"
                        items-url="/countries/lazy-select"
                        clearable
                        return-object
                        hide-details
                        full-load
                        @input="onChanged">
        <template v-slot:item="scope">
          <v-list-item-content>
            <v-list-item-title>{{scope.item.code}}</v-list-item-title>
            <v-list-item-subtitle>{{scope.item.name}}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </trol-lazy-select>
    </v-col>
    <v-col cols="6">
      <v-text-field v-model="model.address.city"
                    :label="$t('city')"
                    :error-messages="validationErrors.collect('address.city')"
                    counter="32"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col cols="12">
      <v-text-field v-model="model.address.street"
                    :label="$t('Address')"
                    :error-messages="validationErrors.collect('address.street')"
                    counter="750"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col cols="12">
      <v-textarea v-model="model.contacts"
                  :label="$t('Contacts')"
                  :error-messages="validationErrors.collect('address.contacts')"
                  rows="2"
                  @input="onChanged" />
    </v-col>
    <v-col cols="12">
      {{$t(methodsTitle)}}
      <v-row no-gutters>
        <v-col v-for="method in loadingUnloadingMethods"
               :key="method.name">
          <v-checkbox v-model="model.loadingMethods"
                      :label="getIntlString(method, 'intlNames', method.defaultName)"
                      :error-messages="validationErrors.collect('loadingMethods')"
                      :value="method"
                      :value-comparator="compareLoadingMethodValue"
                      hide-details
                      @change="onChanged" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <trol-lazy-select v-if="type==='dispatch' && service"
                        :key="service.id"
                        v-model="model.dispatchTerms"
                        :label="$t('dispatchTerms')"
                        :error-messages="validationErrors.collect('dispatchTerms')"
                        item-text="description"
                        item-value="id"
                        item-disabled="dummy"
                        :items-url="`/shipment-terms/service/${service.id}/dispatch`"
                        clearable
                        return-object
                        @input="onChanged" />
      <trol-lazy-select v-if="type==='delivery' && service"
                        :key="service.id"
                        v-model="model.deliveryTerms"
                        :label="$t('deliveryTerms')"
                        :error-messages="validationErrors.collect('deliveryTerms')"
                        item-text="description"
                        item-value="id"
                        item-disabled="dummy"
                        :items-url="`/shipment-terms/service/${service.id}/delivery`"
                        clearable
                        return-object
                        @input="onChanged" />
    </v-col>
    <v-col cols="12">
      <v-expand-transition>
        <v-row v-if="model.dispatchTerms && model.dispatchTerms.courierDeliveryToWarehouse"
               dense>
          <v-col>
            <trol-lazy-select v-model="model.courierDeliveryService"
                              item-text="name"
                              item-value="id"
                              item-disabled="dummy"
                              :items-url="['courier-delivery-services', 'lazy-select', 'enabled']"
                              :label="$t('deliveryService')"
                              :error-messages="validationErrors.collect('courierDeliveryService')"
                              return-object />
          </v-col>
          <v-col>
            <v-text-field v-model="model.courierDeliveryTrackingNumber"
                          :label="$t('trackingNumber')"
                          :error-messages="validationErrors.collect('courierDeliveryTrackingNumber')" />
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-col>
    <v-col cols="12">
      <v-text-field v-if="type === 'dispatch'"
                    v-model="model.loadingNumber"
                    :label="$t('Loading number')"
                    :error-messages="validationErrors.collect('loadingNumber')"
                    counter="100"
                    clearable
                    @input="onChanged" />
    </v-col>
  </v-row>
</template>

<script>

import {getIntlString} from '@/utils/GetIntlStrings';
import {cloneDeep} from 'lodash';

export default {
  name: 'PccOrderCargoHandlingPropertiesEdit',
  props: {
    service: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    warehouseAddressCollection: {
      type: [Array, Object],
      required: true,
    },
    value: {
      type: [Object, Array],
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      loadingUnloadingMethods: [],
      shipperConsigneeMenuOpen: false,
    };
  },
  computed: {
    model: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    shipperConsignee: {
      get () {
        if (this.type === 'dispatch') {
          return 'Shipper';
        } else {
          return 'Consignee';
        }
      },
    },
    methodsTitle: {
      get () {
        if (this.type === 'dispatch') {
          return 'Loading methods';
        } else {
          return 'Unloading methods';
        }
      },
    },
  },
  created () {
    this.loadLoadingUnloadingMethods();
  },
  methods: {
    onWarehouseAddressClick (item) {
      this.model.address          = cloneDeep(item);
      this.model.shipperConsignee = item.shipperConsignee;
      this.onChanged();
      this.shipperConsigneeMenuOpen = false;
    },
    getIntlString,
    onChanged () {
      this.$emit('input', this.value);
    },
    async loadLoadingUnloadingMethods () {
      const result = await this.API.get.progress(['loading-unloading-methods']);
      this.$set(this, 'loadingUnloadingMethods', result.data);
    },
    compareLoadingMethodValue (value1, value2) {
      if (value1 && value1.id && value2 && value2.id) {
        return value1.id === value2.id;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>

</style>
