<template>
  <v-container fluid>
    <h1>{{$t('Requests')}}</h1>
    <search-layout :loading="loading"
                   :can-create="!$isUserReadOnly()"
                   @resetSearch="resetSearch"
                   @search="search">
      <template v-slot:searchFields>
        <v-row dense>
          <v-col cols="12"
                 sm="6"
                 md="3"
                 lg="2">
            <trol-lazy-select v-model="props.searchBy.manager"
                              item-text="name"
                              item-value="id"
                              item-disabled="dummy"
                              :items-url="['managers', 'lazy-select']"
                              selected-url="/managers/lazy-select/{id}"
                              full-load
                              :label="$t('transportirManager')"
                              clearable
                              hide-details />
          </v-col>
          <v-col cols="12"
                 sm="6"
                 md="3"
                 lg="2">
            <trol-lazy-select v-model="props.searchBy.counterpartyContact"
                              item-text="name"
                              item-value="id"
                              item-disabled="dummy"
                              :items-url="['counterparty-contacts', 'lazy-select']"
                              selected-url="/counterparty-contacts/lazy-select/{id}"
                              full-load
                              :label="$t('Manager')"
                              clearable
                              hide-details />
          </v-col>
          <v-col cols="12"
                 sm="6"
                 md="3"
                 lg="3">
            <trol-lazy-select v-model="props.searchBy.currentPublicStage"
                              item-text="publicName"
                              item-value="id"
                              item-disabled="dummy"
                              :items-url="['stage-types', 'lazy-select']"
                              full-load
                              :label="$t('Tracking')"
                              clearable
                              hide-details />
          </v-col>
        </v-row>
      </template>
    </search-layout>
    <v-card class="fit-width mt-2">
      <v-card-text>
        <trol-data-table ref="dataTable"
                         :data="data"
                         :totals="totals"
                         :columns="columns"
                         :page="props.page"
                         :sorting="props.sorting"
                         :storage-key="$options.name"
                         external-filter
                         external-sorting
                         no-quick-filter
                         @changePage="changePage"
                         @sorting="sortingChanged"
                         @quickFilter="onQuickFilter">
          <template v-slot:cell-id="scope">
            <router-link :to="{name: 'OrderProperties', params: {id: scope.cell}}">
              {{scope.cell}}
            </router-link>
          </template>
          <template v-slot:cell-loadingDate="scope">
            {{scope.cell | dateShort}}
          </template>
          <template v-slot:cell-service="scope">
            {{scope.cell.publicName}}
          </template>
          <template v-slot:cell-cargoVolume="scope">
            {{scope.cell|finance(2)}}
          </template>
          <template v-slot:cell-cargoWeight="scope">
            {{scope.cell|finance(3)}}
          </template>
          <template v-slot:cell-actions="scope">
            <v-row no-gutters>
              <v-spacer />
              <v-col class="flex-grow-0 flex-nowrap">
                <v-btn :to="{name: 'OrderProperties', params: {id: 'new', copyId: scope.row.order.id}}"
                       small
                       icon>
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </trol-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import extend from 'lodash/extend';
import cloneDeep from 'lodash/cloneDeep';
import {TrolDataTable, SearchLayout} from '@/components/DataTable';
import {SearchState} from '@/services';

const propsDefaults = {
  sorting: {
    name: 'id',
    direction: -1,
  },
  searchBy: {
    manager: undefined,
    currentPublicStage: undefined,
  },
  page: 0,
  itemsPerPage: 50,
};

export default {
  name: 'PccOrderList',
  components: {TrolDataTable, SearchLayout},
  data () {
    return {
      props: extend({}, propsDefaults),
      data: [],
      totals: {},
      loading: false,
      cancelingApi: this.API.getCancelingApi(),
      quickFilter: '',
    };
  },
  computed: {
    columns () {
      const columns = [
        {label: '№', name: 'id', path: 'order.id', sortable: true},
        {label: this.$t('transportirManager'), name: 'manager', path: 'order.manager.name', sortable: true},
        {label: this.$t('Manager'), name: 'counterpartyContact', path: 'order.counterpartyContact.name', sortable: true},
        {label: this.$t('Loading date'), name: 'loadingDate', path: 'order.loadingDate', sortable: true},
        {label: this.$t('Cargo'), name: 'cargoShortDescription', path: 'order.cargoShortDescription', sortable: true},
        {label: this.$t('Shipper'), name: 'primaryDispatchPointShipperConsignee', path: 'primaryDispatchPointShipperConsignee', sortable: true},
        {label: this.$t('Consignee'), name: 'primaryDeliveryPointShipperConsignee', path: 'primaryDeliveryPointShipperConsignee', sortable: true},
        {label: this.$t('Weight, t.'), name: 'cargoWeight', path: 'order.cargoWeight', sortable: true, right: true},
        {label: this.$t('volumeCbm'), name: 'cargoVolume', path: 'order.cargoVolume', sortable: true, right: true},
        {label: this.$t('Service'), name: 'service', path: 'order.service', sortable: true},
        {label: this.$t('Tracking'), name: 'currentPublicStagePublicName', path: 'currentPublicStagePublicName', sortable: true},
      ];
      if (!this.$isUserReadOnly()) {
        columns.push({label: '', name: 'actions', right: true, persistent: true});
      }
      return columns;
    },
  },
  created () {
    this.props = SearchState.restore(this.$options.name, this.props);
  },
  methods: {
    async loadData () {
      try {
        this.loading      = true;
        const path        = ['orders'];
        const props       = cloneDeep(this.props);
        props.quickFilter = this.quickFilter;
        const success     = await this.cancelingApi.post.progress(path, props);
        SearchState.save(this.$options.name, this.props);
        this.data   = success.data.page;
        this.totals = success.data.totals;
      } finally {
        this.loading = false;
      }
    },
    search () {
      this.props.page = 0;
      this.loadData();
    },
    onQuickFilter (quickFilter) {
      this.quickFilter = quickFilter;
      this.search();
    },
    changePage (page) {
      this.props.page = page;
      this.loadData();
    },
    sortingChanged (sorting) {
      this.props.sorting = sorting;
      this.loadData();
    },
    resetSearch () {
      this.props.searchBy = extend({}, propsDefaults.searchBy);
    },
  },
};
</script>

<style scoped>

</style>
