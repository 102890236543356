<template>
  <div>
    <dialog-wrapper ref="dialog"
                    :title="$t('costOfServices')">
      <template v-slot:activator="{open}">
        <v-card outlined
                class="flex-column d-flex"
                :class="{'fill-height': $vuetify.breakpoint.mdAndUp, 'error lighten-5': hasValidationErrors}"
                @click="onOpen(open)">
          <v-card-text class="text--primary py-2">
            <v-row dense>
              <v-col cols="12"
                     md="">
                <div class="body-1 text--secondary">
                  {{$t('Freight')}}
                </div>
                <div>
                  {{data.freightAmount}} {{data.freightCurrency ? data.freightCurrency.name : '- - -'}}
                </div>
                <div v-if="data.secondaryFreightAmount">
                  {{data.secondaryFreightAmount}} {{data.secondaryFreightCurrency ? data.secondaryFreightCurrency.name : '- - -'}}
                </div>
              </v-col>
              <v-col class="flex-grow-0 hidden-sm-and-down">
                <v-divider vertical />
              </v-col>
              <v-col class="hidden-md-and-up">
                <v-divider />
              </v-col>
              <v-col cols="12"
                     md="">
                <div class="body-1 text--secondary">
                  {{$t('Customs')}}
                </div>
                {{data.customsAmount}} {{data.customsCurrency ? data.customsCurrency.name : '- - -'}}
              </v-col>
              <v-col class="flex-grow-0 hidden-sm-and-down">
                <v-divider vertical />
              </v-col>
              <v-col class="hidden-md-and-up">
                <v-divider />
              </v-col>
              <v-col cols="12"
                     md="">
                <v-row no-gutters>
                  <v-col class="body-1 text--secondary">
                    {{$t('Insurance')}}
                  </v-col>
                  <v-col v-if="changed"
                         class="flex-grow-0">
                    <v-icon color="warning"
                            small>
                      mdi-alert
                    </v-icon>
                  </v-col>
                </v-row>
                {{data.insuranceAmount}} {{data.insuranceCurrency ? data.insuranceCurrency.name : '- - -'}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <pcc-order-cost-of-services-edit v-model="data"
                                       :validation-errors="validationErrors" />
      <template v-slot:actions="{close}">
        <v-btn @click="close">
          {{$t('Close')}}
        </v-btn>
      </template>
    </dialog-wrapper>
  </div>
</template>

<script>
import PccOrderCostOfServicesEdit from '@/views/Orders/PropertiesParts/PccOrderCostOfServicesEdit';

export default {
  name: 'PccOrderCostOfServices',
  components: {PccOrderCostOfServicesEdit},
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    eventBus: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      changed: false,
    };
  },
  computed: {
    data: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        this.changed = true;
      },
    },
    hasValidationErrors () {
      return this.validationErrors.hasErrorsRecursive('customsAmount')
        || this.validationErrors.hasErrorsRecursive('customsCurrency')
        || this.validationErrors.hasErrorsRecursive('freightAmount')
        || this.validationErrors.hasErrorsRecursive('freightCurrency')
        || this.validationErrors.hasErrorsRecursive('secondaryFreightAmount')
        || this.validationErrors.hasErrorsRecursive('secondaryFreightCurrency')
        || this.validationErrors.hasErrorsRecursive('insuranceAmount')
        || this.validationErrors.hasErrorsRecursive('insuranceCurrency');
    },
  },
  created () {
    this.eventBus.$on('saved', this.onSaved);
    if (this.value.prototype && !this.createOrderMode) {
      this.changed = true;
      this.$nextTick(() => {
        this.$refs.dialog.onOpen();
      });
    }
  },
  destroyed () {
    this.eventBus.$off('saved', this.onSaved);
  },
  methods: {
    onSaved () {
      this.changed = false;
    },
    onOpen (open) {
      if (!this.data.toDelete) {
        open();
      }
    },
  },
};
</script>

<style scoped>

</style>
