<template>
  <v-sheet>
    <v-row no-gutters
           align="center"
           class="flex-nowrap caption text--primary">
      <v-col class="text-ellipsis">
        <v-row no-gutters>
          <v-col cols="12">
            {{file.description}}
          </v-col>
          <v-col class="text-no-wrap flex-grow-0 text--secondary pl-1">
            {{file.uploadDateTime|dateTimeShort}}
          </v-col>
          <v-spacer />
          <v-col class="text-no-wrap flex-grow-0 text--secondary">
            {{file.extension}}, {{file.sizeInBytes|fileSize}}
          </v-col>
        </v-row>
      </v-col>
      <v-col class="flex-grow-0 pl-1">
        <v-btn
          :href="downloadLink"
          color="success"
          icon>
          <v-icon>
            mdi-cloud-download
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
  </v-sheet>
</template>

<script>
export default {
  name: 'FileItem',
  props: {
    file: {
      type: Object,
      required: true,
    },
    downloadLink: {
      type: String,
      required: true,
    },
  },
  data () {
    return {};
  },
};
</script>

<style lang="sass"
       scoped>

</style>
