<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12"
             sm="6"
             md="7"
             lg="8">
        <v-row dense>
          <v-col cols="12">
            <v-row align="center"
                   no-gutters>
              <v-col v-if="id !== 'new' && (inProgress || model.archived)"
                     cols="12">
                <v-chip v-if="inProgress"
                        color="success mb-2">
                  {{$t('In progress')}}
                </v-chip>
                <v-chip v-if="model.archived"
                        color="warning mb-2">
                  {{$t('Archived')}}
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col>
                    <h2 class="text--primary">
                      <template v-if="model.id && id !=='new'">
                        {{$t('Request')}} №<span class="accent--text">{{id}}</span> {{$t('from')}} {{model.loadingDate | dateShort}}
                      </template>
                    </h2>
                  </v-col>
                  <v-col v-if="!$isUserReadOnly()"
                         class="flex-grow-0 pl-1">
                    <v-btn color="accent"
                           :loading="loading.save"
                           large
                           @click="save">
                      {{$t('Save')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-2" />
          </v-col>
          <v-col v-if="id !== 'new' && model.id && model.clientApproved === false"
                 cols="12">
            <v-sheet class="pa-2 text-center rounded"
                     color="orange lighten-2">
              <v-row align="center"
                     no-gutters>
                <v-col>
                  {{$t('Approve from your side required')}}
                </v-col>
                <v-col v-if="!$isUserReadOnly()"
                       class="flex-grow-0">
                  <v-btn color="warning"
                         :loading="loading.approve"
                         @click="approve">
                    {{$t('Approve')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col v-if="model.id && model.managerApproved === false"
                 cols="12">
            <v-sheet class="py-3 text-center rounded"
                     color="orange lighten-2">
              {{$t('Manager approvement required')}}
            </v-sheet>
          </v-col>
          <v-col cols="12"
                 lg="6">
            <pcc-order-agreement-properties v-model="model.agreement"
                                            :validation-errors="validationErrors"
                                            :event-bus="eventBus" />
          </v-col>
          <v-col cols="12"
                 lg="6">
            <pcc-order-service-properties v-if="model.service"
                                          v-model="model.service"
                                          :validation-errors="validationErrors"
                                          :event-bus="eventBus" />
          </v-col>
          <v-col cols="12"
                 class="body-1 primary--text">
            <v-row no-gutters>
              <v-col class="flex-grow-0">
                {{$t('Route')}}
              </v-col>
              <v-col v-if="!model.service || !model.service.id"
                     class="flex-grow-0 text-no-wrap px-2 warning--text">
                {{$t('selectService')}}
              </v-col>
              <v-spacer />
              <v-col class="flex-grow-0 pr-2">
                <v-btn small
                       color="success"
                       :disabled="!model.service || !model.service.id"
                       @click="addDispatchPoint">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  {{$t('Loading')}}
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0">
                <v-btn small
                       color="success"
                       :disabled="!model.service || !model.service.id"
                       @click="addDeliveryPoint">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  {{$t('Delivery')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-for="(dispatchPoint, index) in model.dispatchPoints"
                 :key="'dispatch-point-' + index"
                 cols="12"
                 :lg="computeShipmentPointCols">
            <pcc-order-cargo-handling-properties v-model="model.dispatchPoints[index]"
                                                 :service="model.service"
                                                 type="dispatch"
                                                 :validation-errors="validationErrors.getChild('dispatchPoints.'+index)"
                                                 :event-bus="eventBus"
                                                 :create-order-mode="id==='new'"
                                                 @delete="deleteDispatchPoint(model.dispatchPoints[index], index)" />
          </v-col>
          <v-col v-for="(deliveryPoint, index) in model.deliveryPoints"
                 :key="'delivery-point-' + index"
                 cols="12"
                 :lg="computeShipmentPointCols">
            <pcc-order-cargo-handling-properties v-model="model.deliveryPoints[index]"
                                                 :service="model.service"
                                                 type="delivery"
                                                 :validation-errors="validationErrors.getChild('deliveryPoints.'+index)"
                                                 :event-bus="eventBus"
                                                 :create-order-mode="id==='new'"
                                                 @delete="deleteDeliveryPoint(model.deliveryPoints[index], index)" />
          </v-col>
          <v-col cols="12"
                 class="primary--text body-1">
            <v-row no-gutters>
              <v-col>
                {{$t('Customs')}}
              </v-col>
              <v-col class="flex-grow-0 pr-2">
                <v-btn small
                       color="success"
                       :disabled="!model.service || !model.service.id || orderedCustoms.export.length > 0"
                       @click="addExportCustoms">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  {{$t('customsObject.departure')}}
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0">
                <v-btn small
                       color="success"
                       :disabled="!model.service || !model.service.id || orderedCustoms.import.length > 0"
                       @click="addImportCustoms">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  {{$t('customsObject.arrival')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-for="customsPoint in orderedCustoms.export"
                 :key="customsPoint.id"
                 cols="12"
                 lg="6">
            <pcc-order-customs-properties v-model="model.customsPoints[customsPoint.originalIndex]"
                                          :type="customsPoint.customsType ? customsPoint.customsType.toLowerCase() : ''"
                                          :validation-errors="validationErrors.getChild('customsPoints.'+customsPoint.originalIndex)"
                                          :event-bus="eventBus"
                                          :create-order-mode="id==='new'"
                                          :index="customsPoint.originalIndex"
                                          @delete="deleteCustomsPoint(customsPoint)" />
          </v-col>
          <v-col v-if="orderedCustoms.export.length < 1"
                 cols="6"
                 class="hidden-sm-and-down" />
          <v-col v-for="customsPoint in orderedCustoms.import"
                 :key="customsPoint.id"
                 cols="12"
                 lg="6">
            <pcc-order-customs-properties v-model="model.customsPoints[customsPoint.originalIndex]"
                                          :type="customsPoint.customsType ? customsPoint.customsType.toLowerCase() : ''"
                                          :validation-errors="validationErrors.getChild('customsPoints.'+customsPoint.originalIndex)"
                                          :event-bus="eventBus"
                                          :create-order-mode="id==='new'"
                                          :index="customsPoint.originalIndex"
                                          @delete="deleteCustomsPoint(customsPoint)" />
          </v-col>
          <v-col v-if="orderedCustoms.import.length < 1"
                 cols="6"
                 class="hidden-sm-and-down" />
          <v-col cols="12"
                 class="primary--text body-1">
            {{$t('Cargo')}}
          </v-col>
          <v-col cols="12">
            <pcc-order-cargo-properties v-model="model"
                                        :validation-errors="validationErrors"
                                        :event-bus="eventBus" />
          </v-col>
          <v-col cols="12"
                 class="primary--text body-1">
            {{$t('costOfServices')}}
          </v-col>
          <v-col cols="12">
            <pcc-order-cost-of-services v-model="model"
                                        :validation-errors="validationErrors"
                                        :event-bus="eventBus" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"
             sm="6"
             md="5"
             lg="4"
             class="pt-4">
        <pcc-order-additional-properties v-if="model.id"
                                         :order="model"
                                         @orderCopy="onOrderCopy" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import cloneDeep from 'lodash/cloneDeep';
import PccOrderAgreementProperties from '@/views/Orders/PropertiesParts/PccOrderAgreementProperties';
import PccOrderServiceProperties from '@/views/Orders/PropertiesParts/PccOrderServiceProperties';
import PccOrderCargoHandlingProperties from '@/views/Orders/PropertiesParts/PccOrderCargoHandlingProperties';
import PccOrderCustomsProperties from '@/views/Orders/PropertiesParts/PccOrderCustomsProperties';
import PccOrderCargoProperties from '@/views/Orders/PropertiesParts/PccOrderCargoProperties';
import PccOrderCostOfServices from '@/views/Orders/PropertiesParts/PccOrderCostOfServices';
import PccOrderAdditionalProperties from '@/views/Orders/PropertiesParts/PccOrderAdditionalProperties';
import Vue from 'vue';

const prototypeShipmentPoint = {
  address: {},
  loadingMethods: [],
  prototype: true,
};

const prototypeImportCustomsPoint = {customsType: 'import', entityType: 'cis', prototype: true};
const prototypeExportCustomsPoint = {customsType: 'export', entityType: 'other', prototype: true};

const prototypeModel = {
  manager: {},
  cargo: {},
  agreement: {},
  service: {},
  dispatchPoints: [cloneDeep(prototypeShipmentPoint)],
  deliveryPoints: [cloneDeep(prototypeShipmentPoint)],
  customsPoints: [cloneDeep(prototypeExportCustomsPoint), cloneDeep(prototypeImportCustomsPoint)],
  costOfServices: {},
};

export default {
  name: 'PccOrderProperties',
  components: {
    PccOrderAdditionalProperties,
    PccOrderCostOfServices,
    PccOrderCargoProperties,
    PccOrderCustomsProperties,
    PccOrderCargoHandlingProperties,
    PccOrderServiceProperties,
    PccOrderAgreementProperties,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    copyId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data () {
    return {
      model: this.id === 'new' ? cloneDeep(prototypeModel) : {
        customsPoints: [],
        dispatchPoints: [],
        deliveryPoints: [],
      },
      eventBus: new Vue(),
      activeBlock: '',
      loading: {
        save: false,
        approve: false,
      },
      validationErrors: this.$serverValidationParser(),
    };
  },
  computed: {
    computeShipmentPointCols () {
      return (this.model.dispatchPoints.length + this.model.deliveryPoints.length) < 3 ? 6 : 4;
    },
    inProgress () {
      return this.model.id && !this.model.archived && this.model.hasClosedStages && Object.keys(this.model.hasClosedStages).length;
    },
    orderedCustoms () {
      const result = {
        export: [],
        import: [],
      };

      this.model.customsPoints.forEach((item, index) => {
        item.originalIndex = index;
        if (item.customsType === 'export') {
          result.export.push(item);
        } else {
          result.import.push(item);
        }
      });

      return result;
    },
  },
  created () {
    if (this.copyId) {
      this.loadData(this.copyId);
    } else if (this.id !== 'new') {
      this.loadData(this.id);
    }
  },
  methods: {
    async loadData (id) {
      this.loading.save = true;
      const path        = ['orders', id];
      const result      = await this.API.get.progress(path);
      this.model        = result.data;
      if (this.copyId) {
        delete this.model.id;
      }
      // if (this.model.customsPoints.length === 0) {
      //   this.model.customsPoints = cloneDeep(customsPointsDefault);
      // }
      this.loading.save = false;
    },
    addDispatchPoint () {
      this.model.dispatchPoints.push(cloneDeep(prototypeShipmentPoint));
    },
    addDeliveryPoint () {
      this.model.deliveryPoints.push(cloneDeep(prototypeShipmentPoint));
    },
    deleteDispatchPoint (point, index) {
      if (point.prototype) {
        this.model.dispatchPoints.splice(index, 1);
      }
    },
    deleteDeliveryPoint (point, index) {
      if (point.prototype) {
        this.model.deliveryPoints.splice(index, 1);
      }
    },
    addImportCustoms () {
      this.model.customsPoints.push(cloneDeep(prototypeImportCustomsPoint));
    },
    addExportCustoms () {
      this.model.customsPoints.push(cloneDeep(prototypeExportCustomsPoint));
    },
    deleteCustomsPoint (point) {
      if (point.prototype) {
        this.model.customsPoints.splice(point.originalIndex, 1);
      }
    },
    async save () {
      try {
        this.validationErrors.clear();
        this.loading.save         = true;
        this.model.dispatchPoints = this.model.dispatchPoints.filter(item => !item.toDelete);
        this.model.deliveryPoints = this.model.deliveryPoints.filter(item => !item.toDelete);
        const success             = await this.API.post.progress(['orders', this.id], this.model);
        await this.$store.dispatch('snackbar/add', {color: 'success', content: 'Saved'});
        if (this.id === 'new') {
          this.$router.push({name: 'OrderProperties', params: {id: success.data.id}});
        } else {
          this.model = success.data;
          this.eventBus.$emit('saved');
        }
      } catch (error) {
        this.validationErrors = this.$serverValidationParser(error);
      } finally {
        this.loading.save = false;
      }
    },
    async approve () {
      try {
        this.loading.approve = true;
        const path           = ['orders', this.id, 'client-approve'];
        await this.API.post.progress(path);
        this.model.clientApproved = true;
        this.$store.dispatch('snackbar/add', {color: 'success', content: 'Saved'});
      } finally {
        this.loading.approve = false;
      }
    },
    async onOrderCopy (copyId) {
      await this.loadData(copyId);
      delete this.model.id;
    },
  },
};

</script>

<style scoped>

</style>
