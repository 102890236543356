<template>
  <v-row dense>
    <v-col cols="8">
      <v-text-field v-model="model.customsAmount"
                    :label="$t('Customs')"
                    :error-messages="validationErrors.collect('customsAmount')"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col cols="4">
      <trol-lazy-select v-model="model.customsCurrency"
                        :label="$t('Currency')"
                        :error-messages="validationErrors.collect('customsCurrency')"
                        item-text="name"
                        item-value="id"
                        item-disabled="dummy"
                        items-url="/currencies/lazy-select"
                        return-object
                        clearable
                        @input="onChanged" />
    </v-col>
    <v-col cols="8">
      <v-text-field v-model="model.freightAmount"
                    :label="$t('Freight')"
                    :error-messages="validationErrors.collect('freightAmount')"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col cols="4">
      <trol-lazy-select v-model="model.freightCurrency"
                        :label="$t('Currency')"
                        :error-messages="validationErrors.collect('freightCurrency')"
                        item-text="name"
                        item-value="id"
                        item-disabled="dummy"
                        items-url="/currencies/lazy-select"
                        return-object
                        clearable
                        @input="onChanged" />
    </v-col>
    <v-col v-if="!(model.secondaryFreightAmount || secondaryFreight)"
           cols="12"
           class="py-0 my-0">
      <v-btn x-small
             color="primary"
             @click="secondaryFreight = true">
        {{$t('secondaryFreight')}}
      </v-btn>
    </v-col>
    <template v-if="model.secondaryFreightAmount || secondaryFreight">
      <v-col cols="8">
        <v-text-field v-model="model.secondaryFreightAmount"
                      :label="$t('secondaryFreight')"
                      :error-messages="validationErrors.collect('secondaryFreightAmount')"
                      clearable />
      </v-col>
      <v-col cols="4">
        <trol-lazy-select v-model="model.secondaryFreightCurrency"
                          :label="$t('Currency')"
                          :error-messages="validationErrors.collect('secondaryFreightCurrency')"
                          item-text="name"
                          item-value="id"
                          item-disabled="dummy"
                          items-url="/currencies/lazy-select"
                          return-object
                          clearable
                          @input="onChanged" />
      </v-col>
    </template>
    <v-col cols="8">
      <v-text-field v-model="model.insuranceAmount"
                    :label="$t('Insurance')"
                    :error-messages="validationErrors.collect('insuranceAmount')"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col cols="4">
      <trol-lazy-select v-model="model.insuranceCurrency"
                        :label="$t('Currency')"
                        :error-messages="validationErrors.collect('insuranceCurrency')"
                        item-text="name"
                        item-value="id"
                        item-disabled="dummy"
                        items-url="/currencies/lazy-select"
                        return-object
                        clearable
                        @input="onChanged" />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'PccOrderCostOfServicesEdit',
  props: {
    validationErrors: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array],
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      secondaryFreight: false,
    };
  },
  computed: {
    model: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onChanged () {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style scoped>

</style>
