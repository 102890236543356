<template>
  <v-row align="start"
         dense>
    <v-col cols="12">
      <h3 class="font-weight-regular grey--text text--darken-2">
        {{$t(title)}}
      </h3>
    </v-col>
    <v-col cols="12">
      <trol-date-picker v-model="model.date"
                        :label="$t('Date')"
                        :error-messages="validationErrors.collect('date')"
                        @input="onChanged" />
    </v-col>
    <v-col cols="12">
      <trol-lazy-select v-model="model.customsResponsibility"
                        :label="$t('Responsibility')"
                        :error-messages="validationErrors.collect('customsResponsibility')"
                        item-text="description"
                        item-value="code"
                        item-disabled="dummy"
                        :items-url="`/customs/responsibilities/${type}`"
                        clearable
                        return-object
                        hide-details
                        @input="onChanged" />
    </v-col>
    <v-col v-if="type === 'export'"
           cols="12">
      <v-text-field v-model="model.ex1t1"
                    :label="$t('Address') + ' EX1/T1'"
                    :error-messages="validationErrors.collect('ex1t1')"
                    counter="1024"
                    clearable
                    @input="onChanged" />
    </v-col>
    <v-col v-if="type === 'import'"
           cols="12">
      <trol-lazy-select v-model="model.customsPoint"
                        :label="$t('Point')"
                        :error-messages="validationErrors.collect('customsPoint')"
                        item-text="name"
                        item-value="id"
                        item-disabled="dummy"
                        items-url="/customs/points"
                        clearable
                        return-object
                        hide-details
                        @input="onCustomsPointChanged" />
    </v-col>
    <v-col v-if="type === 'import'"
           cols="12">
      <v-textarea v-model="model.details"
                  :label="$t('Details')"
                  :error-messages="validationErrors.collect('details')"
                  rows="5"
                  :disabled="!!model.customsPoint"
                  @input="onChanged" />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'PccOrderCustomsPropertiesEdit',
  props: {
    type: {
      type: String,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array],
      default () {
        return {};
      },
    },
  },
  computed: {
    model: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    title: {
      get () {
        if (this.type === 'export') {
          return 'Customs dispatch';
        } else {
          return 'Customs delivery';
        }
      },
    },
  },
  methods: {
    onChanged () {
      this.$emit('input', this.value);
    },
    onCustomsPointChanged () {
      if (this.model.customsPoint) {
        this.model.details = this.model.customsPoint.textRepresentation;
      }
      this.onChanged();
    },
  },
};
</script>

<style scoped>

</style>
