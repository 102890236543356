<template>
  <v-fade-transition hide-on-leave>
    <v-radio-group v-if="!loading"
                   v-model="model"
                   :error-messages="validationErrors.collect('service')">
      <v-radio v-for="item in data"
               :key="item.id"
               :value="item"
               :label="item.publicName" />
    </v-radio-group>
    <v-row v-else
           align="center"
           justify="center">
      <v-col class="flex-grow-0">
        <v-progress-circular indeterminate
                             color="accent"
                             size="100" />
      </v-col>
    </v-row>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'PccOrderServicePropertiesEdit',
  props: {
    value: {
      type: [Object, Array],
      default () {
        return {};
      },
    },
    validationErrors: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      data: [],
      loading: true,
    };
  },
  computed: {
    model: {
      get () {
        return this.data.find(item => item.id === this.value.id);
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
  created () {
    this.loadData();
  },
  methods: {
    async loadData () {
      this.loading = true;
      const path   = ['services'];
      const result = await this.API.get.progress(path, {itemsPerPage: 1000, page: 0});
      this.data    = result.data;
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
