<template>
  <v-sheet v-if="collection.length >0"
           outlined
           class="mt-2 pa-0">
    <!--    <v-simple-table dense>-->
    <!--      <thead>-->
    <!--        <tr>-->
    <!--          <th class="text-start primary white&#45;&#45;text">-->
    <!--            {{$t('warehouseAccounting')}}-->
    <!--          </th>-->
    <!--          <th class="text-start primary white&#45;&#45;text">-->
    <!--            {{$t('ItemsShort')}}-->
    <!--          </th>-->
    <!--          <th class="text-start primary white&#45;&#45;text">-->
    <!--            LxWxH (cm):-->
    <!--          </th>-->
    <!--          <th class="text-start primary white&#45;&#45;text">-->
    <!--            М³:-->
    <!--          </th>-->
    <!--          <th class="text-start primary white&#45;&#45;text">-->
    <!--            {{$t('Weight')}} (kg)-->
    <!--          </th>-->
    <!--          <th class="text-start primary white&#45;&#45;text">-->
    <!--            {{$t('warehouse')}}-->
    <!--          </th>-->
    <!--        </tr>-->
    <!--      </thead>-->
    <!--      <tbody>-->
    <!--        <warehouse-accounting-row v-for="item in collection"-->
    <!--                                  :key="item.id"-->
    <!--                                  :item="item" />-->
    <!--      </tbody>-->
    <!--    </v-simple-table>-->
    <v-data-table :headers="headers"
                  :items="collection"
                  hide-default-footer
                  :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  dense
                  class="ma-0">
      <template v-slot:item.weight="scope">
        {{scope.value|finance(2)}}
      </template>
      <template v-slot:item.warehouse="scope">
        {{scope.value.notificationNameRu || scope.value.name}}
      </template>
      <template v-slot:item.volume="scope">
        {{scope.value|finance(2)}}
      </template>
      <template v-slot:item.creationTimestamp="scope">
        {{scope.value|dateShort}}
      </template>
      <template v-slot:item.dimensions="scope">
        {{scope.item.length * 100|finance(0)}}
        <span class="text--secondary">x</span>
        {{scope.item.width * 100|finance(0)}}
        <span class="text--secondary">x</span>
        {{scope.item.height * 100|finance(0)}}
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
export default {
  name: 'PccOrderWarehouseAccounting',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      collection: [],
      headers: [
        {
          text: this.$t('warehouseAccounting'),
          align: 'start',
          sortable: false,
          value: 'creationTimestamp',
          class: 'primary white--text',
        },
        {
          text: this.$t('ItemsShort'),
          align: 'end',
          sortable: false,
          value: 'quantity',
          class: 'primary white--text',
        },
        {
          text: 'LxWxH (cm)',
          align: 'end',
          sortable: false,
          value: 'dimensions',
          class: 'primary white--text',
        },
        {
          text: 'М³',
          align: 'end',
          sortable: false,
          value: 'volume',
          class: 'primary white--text',
        },
        {
          text: this.$t('Weight') + ' (kg)',
          align: 'end',
          sortable: false,
          value: 'weight',
          class: 'primary white--text',
        },
        {
          text: this.$t('warehouse'),
          align: 'start',
          sortable: false,
          value: 'warehouse',
          class: 'primary white--text',
        },
      ],
      loading: false,
    };
  },
  created () {
    this.loadData();
  },
  methods: {
    async loadData () {
      this.loading = true;
      try {
        const success   = await this.API.get(['orders', this.order.id, 'warehouse', 'accounting']);
        this.collection = success.data;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
