<template>
  <dialog-wrapper ref="dialog"
                  :title="$t('Customs')+' '+$t('customsObject.' + type)">
    <template v-slot:activator="{open}">
      <v-card outlined
              :class="{'fill-height': $vuetify.breakpoint.mdAndUp, 'error lighten-5': hasValidationErrors}"
              @click="onOpen(open)">
        <v-card-title class="pb-1 body-1">
          <v-row no-gutters
                 align="center">
            <v-col class="text--secondary">
              {{$t('Customs')}} {{$t('customsObject.' + type)}}
            </v-col>
            <v-col class="flex-grow-0 text-no-wrap">
              {{data.date|dateShort}}
            </v-col>
            <v-col v-if="changed"
                   class="flex-grow-0 pl-1">
              <v-icon color="warning"
                      small>
                mdi-alert
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p>
            <span class="font-weight-bold text--primary">{{$t('Customs registration')}}: </span>{{data.description}}
          </p>
          <p v-if="data.customsResponsibility">
            {{data.customsResponsibility.description}}
          </p>
          <p v-if="type === 'export'">
            <span class="font-weight-bold text--primary">EX1/T1: </span> {{data.ex1t1}}
          </p>
          <p>
            {{data.details}}
          </p>
        </v-card-text>
        <v-overlay :value="!!data.toDelete"
                   absolute
                   z-index="4"
                   opacity=".90"
                   @click.capture.prevent.stop="t=0">
          <v-row dense
                 justify="center">
            <v-col class="flex-grow-0">
              <v-icon x-large
                      color="error">
                mdi-delete-forever
              </v-icon>
            </v-col>
            <v-col cols="12" />
            <v-col class="flex-grow-0">
              <v-btn color="warning"
                     text
                     @click="data.toDelete = false">
                {{$t('cancel')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-overlay>
      </v-card>
    </template>
    <pcc-order-customs-properties-edit v-model="data"
                                       :type="data.customsType.toLowerCase()"
                                       :validation-errors="validationErrors" />
    <template v-slot:actions="{close}">
      <v-row no-gutters>
        <v-col class="flex-grow-0">
          <v-btn @click="close">
            {{$t('Close')}}
          </v-btn>
        </v-col>
        <v-col class="px-1" />
        <v-col class="flex-grow-0">
          <v-btn color="error"
                 @click="onDelete(close)">
            {{$t('delete')}}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </dialog-wrapper>
</template>

<script>
import PccOrderCustomsPropertiesEdit from '@/views/Orders/PropertiesParts/PccOrderCustomsPropertiesEdit';

export default {
  name: 'PccOrderCustomsProperties',
  components: {PccOrderCustomsPropertiesEdit},
  props: {
    value: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    eventBus: {
      type: Object,
      required: true,
    },
    createOrderMode: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      changed: false,
    };
  },
  computed: {
    data: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        this.changed = true;
      },
    },
    hasValidationErrors () {
      return this.validationErrors.hasErrorsRecursive('date')
        || this.validationErrors.hasErrorsRecursive('customsResponsibility')
        || this.validationErrors.hasErrorsRecursive('ex1t1')
        || this.validationErrors.hasErrorsRecursive('customsPoint')
        || this.validationErrors.hasErrorsRecursive('details');
    },
  },
  created () {
    this.eventBus.$on('saved', this.onSaved);
    if (this.value.prototype && !this.createOrderMode) {
      this.changed = true;
      this.$nextTick(() => {
        this.$refs.dialog.onOpen();
      });
    }
  },
  destroyed () {
    this.eventBus.$off('saved', this.onSaved);
  },
  methods: {
    onSaved () {
      this.changed = false;
    },
    onDelete (close) {
      this.data.toDelete = true;
      this.$emit('delete');
      close();
    },
    onOpen (open) {
      if (!this.data.toDelete) {
        open();
      }
    },
  },
};
</script>

<style scoped>

</style>
