<template>
  <dialog-wrapper :title="$t('Agreement')">
    <template v-slot:activator="{open}">
      <v-card :class="{'fill-height': $vuetify.breakpoint.mdAndUp, 'error lighten-5': hasValidationErrors}"
              outlined
              @click="open">
        <v-card-text class="text--primary">
          <v-row dense>
            <v-col class="body-1 text--secondary">
              {{$t('Agreement')}}
            </v-col>
            <v-col v-if="changed"
                   class="flex-grow-0">
              <v-icon color="warning"
                      small>
                mdi-alert
              </v-icon>
            </v-col>
            <v-col cols="12">
              <div v-if="data && data.id">
                <div class=" text--secondary">
                  <strong class="text--primary">{{data.name}}</strong>
                  {{$t('from')}} {{data.startDate | dateShort}}
                </div>
                <div class="caption text--secondary">
                  {{data|accessor('company.name', '')}} - {{data|accessor('legalEntity.name', '')}}
                </div>
              </div>
              <div v-else>
                - - -
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <pcc-order-agreement-properties-edit v-model="data"
                                         :validation-errors="validationErrors" />
    <template v-slot:actions="{close}">
      <v-btn @click="close">
        {{$t('Close')}}
      </v-btn>
    </template>
  </dialog-wrapper>
</template>

<script>
import PccOrderAgreementPropertiesEdit from '@/views/Orders/PropertiesParts/PccOrderAgreementPropertiesEdit';

export default {
  name: 'PccOrderAgreementProperties',
  components: {PccOrderAgreementPropertiesEdit},
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    eventBus: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      changed: false,
    };
  },
  computed: {
    data: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        this.changed = true;
      },
    },
    hasValidationErrors () {
      return this.validationErrors.hasErrorsRecursive('agreement');
    },
  },
  created () {
    this.eventBus.$on('saved', this.onSaved);
  },
  destroyed () {
    this.eventBus.$off('saved', this.onSaved);
  },
  methods: {
    onSaved () {
      this.changed = false;
    },
  },
};
</script>

<style scoped>

</style>
