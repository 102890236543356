<template>
  <div class="fill-height">
    <dialog-wrapper :title="$t('Service')">
      <template v-slot:activator="{open}">
        <v-card :class="{'fill-height': $vuetify.breakpoint.mdAndUp, 'error lighten-5': hasValidationErrors}"
                outlined
                @click="open">
          <v-card-text class="text--primary">
            <v-row dense>
              <v-col class="body-1 text--secondary">
                {{$t('Service')}}
              </v-col>
              <v-col v-if="changed"
                     class="flex-grow-0">
                <v-icon color="warning"
                        small>
                  mdi-alert
                </v-icon>
              </v-col>
              <v-col cols="12"
                     class="font-weight-bold">
                {{data.publicName}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <pcc-order-service-properties-edit v-model="data"
                                         :validation-errors="validationErrors" />
      <template v-slot:actions="{close}">
        <v-btn @click="close">
          {{$t('Close')}}
        </v-btn>
      </template>
    </dialog-wrapper>
  </div>
</template>

<script>
import PccOrderServicePropertiesEdit from '@/views/Orders/PropertiesParts/PccOrderServicePropertiesEdit';

export default {
  name: 'PccOrderServiceProperties',
  components: {PccOrderServicePropertiesEdit},
  props: {
    value: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: true,
    },
    eventBus: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      changed: false,
    };
  },
  computed: {
    data: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        this.changed = true;
      },
    },
    hasValidationErrors () {
      return this.validationErrors.hasErrorsRecursive('service');
    },
  },
  created () {
    this.eventBus.$on('saved', this.onSaved);
  },
  destroyed () {
    this.eventBus.$off('saved', this.onSaved);
  },
  methods: {
    onSaved () {
      this.changed = false;
    },
  },
};
</script>

<style scoped>

</style>
