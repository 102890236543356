<template>
  <v-row no-gutters>
    <v-col class="flex-grow-0">
      <v-btn icon
             x-small
             @click="expanded = !expanded">
        <v-icon :class="{flipY: expanded}">
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </v-col>
    <v-col>
      <span class="primary--text">{{stage[0].actualEndDateTime|dateShort}} </span>
      {{stage[0].stageTypeName}}
    </v-col>
    <v-col cols="12"
           class="pl-5">
      <v-expand-transition>
        <div v-if="expanded"
             class="caption text--secondary">
          {{stage.notification}}
        </div>
      </v-expand-transition>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TrackAndTraceRow',
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      expanded: false,
    };
  },
};
</script>

<style lang="sass"
       scoped>
.flipY
  transform: scaleY(-1)
</style>
