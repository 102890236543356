<template>
  <v-card outlined>
    <v-card-title class="body-1">
      {{$t('Tracking').toUpperCase()}}:
    </v-card-title>
    <v-card-text class="text--primary">
      <track-and-trace-row v-for="stage in data.filter(item=>!!item[0].actualEndDateTime)"
                           :key="stage.id"
                           :stage="stage" />
    </v-card-text>
  </v-card>
</template>

<script>
import TrackAndTraceRow from '@/views/Orders/PropertiesParts/TrackAndTraceRow';

export default {
  name: 'TrackAndTrace',
  components: {TrackAndTraceRow},
  props: {
    orderId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data () {
    return {
      loading: false,
      data: [],
    };
  },
  created () {
    if (this.orderId) {
      this.loadData();
    }
  },
  methods: {
    async loadData () {
      try {
        const path    = ['stages', this.orderId];
        const success = await this.API.get.progress(path, this.props);
        this.data     = success.data.reverse();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass"
       scoped>

</style>
