<template>
  <v-fade-transition hide-on-leave>
    <v-radio-group v-if="!loading"
                   v-model="model"
                   :error-messages="validationErrors.collect('agreement')">
      <v-radio v-for="item in data"
               :key="item.id"
               :value="item">
        <template v-slot:label>
          <v-row no-gutters>
            <v-col cols="12">
              <span class="font-weight-bold text--primary">{{item.name}}</span> {{$t('from')}} {{item.startDate | dateShort}}
            </v-col>
            <v-col cols="12"
                   class="caption">
              {{item|accessor('company.name', '')}} - {{item|accessor('legalEntity.name', '')}}
            </v-col>
          </v-row>
        </template>
      </v-radio>
    </v-radio-group>
    <v-row v-else
           align="center"
           justify="center">
      <v-col class="flex-grow-0">
        <v-progress-circular indeterminate
                             color="accent"
                             size="100" />
      </v-col>
    </v-row>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'PccOrderAgreementPropertiesEdit',
  props: {
    value: {
      type: Object,
      default () {
        return {};
      },
    },
    validationErrors: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: true,
      data: [],
      tmp: undefined,
    };
  },
  computed: {
    model: {
      get () {
        return this.data.find(item => item.id === this.value.id);
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
  created () {
    this.loadData();
  },
  methods: {
    async loadData () {
      this.loading = true;
      const path   = ['agreements'];
      const result = await this.API.get.progress(path);
      this.data    = result.data;
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
