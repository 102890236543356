<template>
  <div>
    <v-card v-if="!$isUserReadOnly()"
            outlined>
      <v-card-text>
        <v-row dense>
          <v-col cols="12"
                 md="">
            <v-btn color="default"
                   block
                   @click="onCopyRequestClick">
              {{$t('Copy request')}}
            </v-btn>
          </v-col>
          <v-col cols="12"
                 md="">
            <v-btn v-if="$getValue(order,'agreement.legalEntity.hasCustomerApplicationTemplate')"
                   color="default"
                   block
                   :loading="loading.applicationCreate"
                   @click="onOnCreateWordRequestClick">
              {{$t('Create word application')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined
            class="mt-4">
      <v-card-text class="body-1 text--primary">
        <div class="properties-grid">
          <div class="properties-grid-label text-no-wrap"
               style="overflow-wrap: normal;">
            {{$t('transportirManager').toUpperCase()}}:
          </div>
          <div>
            <span class="mx-1 accent--text font-weight-regular">{{order.manager.name.toUpperCase()}}</span>
          </div>
          <template v-if="order.counterpartyContact">
            <div class="properties-grid-label text-no-wrap">
              {{$t('Manager').toUpperCase()}}:
            </div>
            <div>
              <span class="mx-1 accent--text font-weight-regular">{{order.counterpartyContact.name.toUpperCase()}}</span>
            </div>
          </template>
        </div>
        <!--        <v-row dense>-->
        <!--          <v-col cols="12">-->
        <!--            {{$t('transportirManager').toUpperCase()}}: <span-->
        <!--            class="mx-1 accent&#45;&#45;text font-weight-regular">{{order.manager.name.toUpperCase()}}</span>-->
        <!--          </v-col>-->
        <!--          <v-col v-if="order.counterpartyContact"-->
        <!--                 cols="12">-->
        <!--            {{$t('Manager').toUpperCase()}}: <span-->
        <!--            class="mx-1 accent&#45;&#45;text font-weight-regular">{{order.counterpartyContact.name.toUpperCase()}}</span>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-card-text>
    </v-card>
    <v-card outlined
            class="mt-4">
      <v-card-title class="body-1 text--primary">
        {{$t('Financial documents').toUpperCase()}}
      </v-card-title>
      <v-card-text class="text--primary">
        <v-row v-for="bill in bills"
               :key="bill.id"
               dense>
          <v-col cols="12">
            <span class="text--secondary">{{$t('Bill')}}</span> <strong>№ {{bill.documentNumber}}</strong>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col v-for="file in bill.files"
                 :key="file.id"
                 cols="12"
                 class="pl-4">
            <file-item :file="file"
                       :download-link="getDownloadFileLink(file)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined
            class="mt-4">
      <v-card-title class="body-1 text--primary pb-2">
        {{$t('Files').toUpperCase()}}:
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <file-item v-for="file in files"
                       :key="file.id"
                       :file="file"
                       :download-link="getDownloadFileLink(file)" />
          </v-col>
          <v-col cols="12">
            <v-btn v-if="!$isUserReadOnly()"
                   color="success"
                   block
                   outlined
                   @click="$refs.uploadInput.click()">
              {{$t('uploadFile')}}
            </v-btn>
            <input ref="uploadInput"
                   type="file"
                   hidden
                   @change="uploadFile($event.target.files)">
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <TrackAndTrace :order-id="order.id"
                   class="mt-4" />
  </div>
</template>

<script>

import {mapState} from 'vuex';
import FileItem from '@/components/File/FileItem';
import TrackAndTrace from '@/views/Orders/PropertiesParts/TrackAndTrace';

export default {
  name: 'PccOrderAdditionalProperties',
  components: {TrackAndTrace, FileItem},
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      uploading: false,
      fileLoading: false,
      loading: {
        applicationCreate: false,
      },
      files: [],
      bills: [],
    };
  },
  computed: mapState([
    'user',
  ]),
  created () {
    this.loadFiles();
    this.loadBills();
  },
  methods: {
    async loadFiles () {
      this.fileLoading = true;
      const path       = ['orders', this.order.id, 'files'];
      const success    = await this.API.get.progress(path, this.model);
      this.files       = success.data;
      this.fileLoading = false;
    },
    async loadBills () {
      this.fileLoading = true;
      const path       = ['printable-bills', this.order.id];
      const success    = await this.API.get.progress(path, this.model);
      this.bills       = success.data;
      this.fileLoading = false;
    },
    async uploadFile (files) {
      for (let i = 0; i < files.length; i++) {
        const data = new FormData();
        data.append('trol_order_id', this.order.id);
        data.append('file', files[i]);
        try {
          this.uploading = true;
          const path     = ['orders', this.order.id, 'files'];
          const result   = await this.API.post.progress(path, data);
          result.data.forEach(file => {
            this.files.push(file);
          });
        } catch (error) {
        } finally {
          this.uploading = false;
        }
      }
    },
    getDownloadFileLink (file) {
      return this.API.getBaseUrl() + '/orders/' + this.order.id + '/files/' + file.id + '?bearer=' + this.API.getToken();
    },
    async onOnCreateWordRequestClick () {
      this.loading.applicationCreate = true;
      try {
        await this.API.post(['orders', this.order.id, 'files', 'generate-application']);
        this.loadFiles();
      } finally {
        this.loading.applicationCreate = false;
      }
    },
    async onCopyRequestClick () {
      await this.$router.push({name: 'OrderProperties', params: {id: 'new', copyId: this.order.id}});
      this.$emit('orderCopy', this.order.id);
    },
  },
};
</script>

<style scoped>

</style>
